/**
 * UTF8 Keyboard
 */
(function($) {
    /**
     * hasUTF8Keyboard
     * 
     * @param el
     * @returns {boolean}
     */
    $.hasUTF8Keyboard = function (el) {
        return $(el).data('UTF8Keyboard') !== undefined;
    };

    /**
     * UTF8Keyboard
     * 
     * @param el
     * @param options
     * @constructor
     */
    $.UTF8Keyboard = function (el, options) {
        var base    = this;
        base.$el    = $(el);
        base.el     = el;

        base.$el.data('UTF8Keyboard', base);

        base.isOpen = false;

        base.init = function() {
            base.options = $.extend({}, $.UTF8Keyboard.defaultOptions, options);

            // TODO: provide better accessible code, add aria-attributes when necessary
            if ($.hasUTF8Keyboard(base.el)) {
                var overlayTitle    = 'Sonderzeichen'; // TODO: Add lang ...
                var tpl             = '<div class="input-append"><a class="' + base.options.buttonClass + ' ' + base.options.buttonClass + '-append ' + base.options.buttonClass + '-icon" href="#" title="' + overlayTitle + '"><span class="' + base.options.iconClass + ' ' + base.options.iconClass + '-omega"></span></a></div>';
                var overlayTpl      = '<span class="popover-close"><span class="fe-icon fe-icon-close"></span></span><div class="assistive-keyboard-wrapper"><ul class="assistive-keyboard">#keys#</ul></div>';
                var wrapperEle      = $(tpl);
                var keys            = '';

                for (var i = 0 ,len = base.options.keys.length; i < len; i++) {
                    keys += '<li><button type="button" class="' + base.options.buttonClass + '">' + String.fromCharCode('0x' + base.options.keys[i]) + '</button></li>';
                }

                overlayTpl = overlayTpl.replace('#keys#', keys);

                base.$el.replaceWith(wrapperEle);
                wrapperEle.prepend(base.$el);

                $(base.el).popover({
                    content: overlayTpl,
                    html: true,
                    title: overlayTitle,
                    trigger: 'manual',
                    template: '<div class="popover popover-assistive-keyboard"><div class="popover-title-wrapper"><span class="popover-title"></span></div><div class="popover-content"></div></div>',
                    placement: 'bottom'
                });

                wrapperEle.find('.' + base.options.buttonClass).on('click', function (event) {
                    event.preventDefault();

                    if (base.isOpen) {
                        base.close();
                     } else {
                        base.open();
                    }
                });
            }
        };

        base.onCLick = function () {
            var key         = $(this).text();
            var $txt        = $(base.el);
            var caretPos    = $txt[0].selectionStart;
            var text        = $txt.val();

            $txt.val(text.substring(0, caretPos) + key + text.substring(caretPos));

            $txt[0].selectionStart  = caretPos + 1;
            $txt[0].selectionEnd    = caretPos + 1;

            // Set focus back to input field
            // after clicking a button
            $txt.focus();
        };

        base.open = function () {
            base.isOpen = true;

            $(base.el).popover('show');

            var popover = $(base.el).data('popover');

            if (popover && popover.$tip) {
                popover
                    .$tip
                    .find('ul')
                    .on('click', 'button', base.onCLick);

                popover
                    .$tip
                    .find('.popover-close')
                    .on('click', base.close);
            }

            // Set focus back to input field
            // after opening the keyboard
            $(base.el).focus();
        };

        base.close = function () {
            base.isOpen = false;
            var popover = $(base.el).data('popover');

            if (popover && popover.$tip) {
                popover
                    .$tip
                    .find('ul')
                    .off('click');

                popover
                    .$tip
                    .find('.popover-close')
                    .off('click');
          }

            // Hide keyboard popover
            $(base.el).popover('hide');

            // Set focus back to input field
            // after closing the keyboard
            $(base.el).focus();
        };

        // Initialize the plugin
        base.init();
    };

    /**
     * UTF8Keyboard.defaultOptions
     * 
     * Sets the option defaults
     * 
     * @type {{buttonClass: string, keys: string[], iconClass: string}}
     */
    $.UTF8Keyboard.defaultOptions = {
        iconClass: 'fe-icon',
        buttonClass: 'button',
        keys: [
            '2070',
            '00B9',
            '00B2',
            '00B3',
            '2074',
            '2075',
            '2076',
            '2077',
            '2078',
            '2079',
            '207F',
            '2080',
            '2081',
            '2082',
            '2083',
            '2084',
            '2085',
            '2086',
            '2087',
            '2088',
            '2089',
            '03B1',
            '03B2',
            '03B3',
            '03B4',
            '03B5',
            '03B6',
            '03B7',
            '03B8',
            '03B9',
            '03BA',
            '03BB',
            '03BC',
            '03BD',
            '03BE',
            '03BF',
            '03C0',
            '03C1',
            '03C2',
            '03C3',
            '03C4',
            '03C5',
            '03C6',
            '03C7',
            '03C8',
            '03C9',
            '0391',
            '0392',
            '0393',
            '0394',
            '0395',
            '0396',
            '0397',
            '0398',
            '0399',
            '039A',
            '039B',
            '039C',
            '039D',
            '039E',
            '039F',
            '03A0',
            '03A1',
            //'03A2',
            '03A3',
            '03A4',
            '03A5',
            '03A6',
            '03A7',
            '03A8',
            '03A9'
        ]
    };


    /**
     * fn.UTF8Keyboard
     * 
     * Returns enhanced elements that will fix to the top of the page when the page is scrolled.
     * 
     * @param options
     * @returns {*}
     * @constructor
     */
    $.fn.UTF8Keyboard = function(options) {
        return this.each(function() {
            (new $.UTF8Keyboard(this, options));
        });
    };
})(jQuery);